<style lang="less" scoped>
* {
  margin: 0;
}
.container-water-fall {
  width: 100%;
  box-sizing: border-box;
  h4 {
    padding-top: 56px;
    padding-bottom: 28px;
    font-family: PingFangSC-Medium;
    font-size: 36px;
    color: #000000;
    letter-spacing: 1px;
    text-align: justify;
  }
  button {
    background-color: #ff0;
    color: #24292e;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 0.25em;
    width: 100px;
    line-height: 26px;
    font-size: 13px;
    margin: 4px 0;
    margin-right: 4px;
    cursor: pointer;
    outline: none;
    &.blue-light {
      background: #27fbc2;
    }
  }
  button:hover {
    background-image: linear-gradient(-180deg, #fafbfc, #ccc 90%);
  }

  .cell-item {
    width: 100%;
    background: #ffffff;
    border: 2px solid #f0f0f0;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    .item-body {
      padding: 12px;
      .item-desc {
        font-size: 15px;
        color: #333333;
        line-height: 15px;
        font-weight: bold;
      }
      .item-footer {
        margin-top: 22px;
        position: relative;
        display: flex;
        align-items: center;
        .avatar {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .name {
          max-width: 150px;
          margin-left: 10px;
          font-size: 14px;
          color: #999999;
        }
        .like {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          &.active {
            i {
            }
            .like-total {
              color: #ff4479;
            }
          }
          i {
            width: 28px;
            display: block;
          }
          .like-total {
            margin-left: 10px;
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
  }
  .mengceng{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background: rgba(12,9,9,0.8);
    .dlog-center{
      width: 1044px;
      height: 698px;
      background: #FFFFFF;
      border-radius: 18px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -349px;
      margin-left: -522px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 612px;
        height: 446px;
      }
      .go-downLoad{
        width: 794px;
        height: 86px;
        background: #F82B34;
        border-radius: 8px;
        text-align: center;
        color: #fff;
        line-height: 86px;
        font-size: 33px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }
      .close{
        position: fixed;
        right: 38px;
        top: 46px;
        width: 38px;
        height: 38px;
        background-image: url(https://p0.ssl.qhimg.com/t01f3ae46f854c67b17.png);
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
}
.githubdata {
  float: right;
  margin-right: 90px;
  img {
    width: 14px;
    // height: 16px;
  }
}
</style>
<template>
  <div class="container-water-fall">
    <!-- <h1 style="position: fixed;left: 0;top: 100px;font-style: 15px;color:blue;z-index: 1000;">{{loadstatus}}</h1> -->
    <!-- <div class="btn-group">
      <button @click="loadmore">LoadMore</button>
      <button @click="switchCol(5)">5column(列)</button>
      <button @click="switchCol(8)">8column(列)</button>
      <button @click="switchCol(10)">10column(列)</button>
      <button @click="reset">reset(重置)</button>
      <a
        style="color: red"
        href="https://github.com/Rise-Devin/vue-waterfall2/blob/master/README.md"
        target="_blank"
        >GITHUB</a
      >
      <b style="color: blue">滚动至底部可触发loadmore</b>
    </div> -->
    <waterfall :col="5" :data="data" @loadmore="loadmore" :gutterWidth="10">
      <div
        class="cell-item"
        v-for="(item, index) in data"
        :key="index"
        @click="() => handleClick(item)"
      >
        <img v-if="item" :src="item" alt="加载错误" />
      </div>
    </waterfall>
    <div class="mengceng" v-if="dloagVisible">
      <div class="dlog-center">
        <!-- <img :src="'https://p5.ssl.qhimg.com/t01979d34891231ecd5.jpg'" alt=""> -->
        <el-watermark :font="font" style="height: 446px;
        margin-top: 74px;
        margin-bottom: 50px;" content="神州动漫">
          <img :src="dloagImgSrc" alt="">
        </el-watermark>
        <div class="go-downLoad" @click="downLoad">去下载</div>
        <span class="close" @click="close"></span>
      </div>
    </div>
    <!-- <loading :show="loading" /> -->
  </div>
</template>

<script>
/*
  注意:
  1.itemWidth需要与gutterWidth一起使用才会生效，否则会进行自适应宽度
  2.使用了waterfall的组件不允许使用scoped,否则样式会有问题
*/
// import loading from "./loading";
// import VueWaterfall2 from 'vue-waterfall2';


 const getImageDataURL = (image) => {
    // 创建画布
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    // 以图片为背景剪裁画布
    ctx.drawImage(image, 0, 0, image.width, image.height);
    // 获取图片后缀名
    const extension = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
    // 某些图片 url 可能没有后缀名，默认是 png
    return canvas.toDataURL('image/' + extension, 1);
}
const downLoad = (downloadName, url) => {
    const tag = document.createElement('a');
    // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
    tag.setAttribute('download', downloadName.replace(/\./g, '。'));

    const image = new Image();
    // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
    image.src = url + '?time=' + new Date().getTime();
    //重要，设置 crossOrigin 属性，否则图片跨域会报错
    image.setAttribute('crossOrigin', 'Anonymous');
    // 图片未加载完成时操作会报错
    image.onload = () => {
        tag.href = getImageDataURL(image);
        tag.click();
    };
}
export default {
  props: {
    imgData:Array,
    zuopinAction:Number,
  },
  data() {
    return {
      data: [],
      col: 5,
      loading: false,
      dloagVisible:false,
      dloagImgSrc:'',
      gitHubData: {},
      originData: [],
    };
  },
  computed: {
    itemWidth() {
      return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    },
    gutterWidth() {
      return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    },
  },
  methods: {
    toGitHub() {
      window.open(
        "https://github.com/Rise-Devin/vue-waterfall2/blob/master/README.md",
        "_blank"
      );
    },
    reset() {
      this.data = [];
    },

    switchCol(col) {
      this.col = col;
    },

    handleClick(item) {
      this.dloagVisible  = true
      this.dloagImgSrc = item
    },
    close(){
      this.dloagVisible  = false
    },
    downLoad(){
      if(this.zuopinAction == 1) {
        downLoad('图片',this.dloagImgSrc)
        alert('下载成功')
        this.dloagVisible  = false
      }else{
        let ispay = localStorage.getItem("ispay");
        if (ispay) {
          downLoad('VIP图片',this.dloagImgSrc)
          alert('下载成功')
        }else{
          this.$emit('childData');
        }
        this.dloagVisible  = false
      }
    },
    loadmore() {
      this.loading = true;
      setTimeout(() => {
        this.data = Array.from(this.data.concat(this.originData));
        this.loading = false;
        console.log(this.data);
      }, 1000);
    },
  },
  mounted() {
    this.data = this.imgData;
  }
};
</script>
