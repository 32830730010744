<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: url(https://p1.ssl.qhimg.com/t011ad9c786faec8107.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
*{
  margin: 0;
  padding: 0;
}
body{
  width: 100%;
  height: 100vh;
}
</style>
