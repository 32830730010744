<template>
  <div class="hello">
    <div class="header">
      <div class="name">欢迎来到北京众华科技有限公司！</div>
      <div class="login" v-if="show != 'pay' && loginFrom != 'login'">
        <button @click="gotoLink('login')">登录</button
        ><button @click="gotoLink('register')">注册</button>
      </div>
      <div class="login" v-else>用户:{{ userName }}     <span @click="outLogin" style="cursor: pointer;">退出登录</span></div>
    </div>
    <div class="nav">
      <div class="logo" @click="gotoLink('body')"></div>
      <div class="nav-tobar">
        <!-- <div class="item " :class="navAction == 0 ? 'item-action' : ''" @click="toMaodian('home',0)">网站首页</div> -->
        <div
          class="item"
          :class="navAction == 2 ? 'item-action' : ''"
          @click="toMaodian('zuopin', 2)"
        >
          动漫作品
        </div>
        <div
          class="item"
          :class="navAction == 1 ? 'item-action' : ''"
          @click="toMaodian('home', 1)"
        >
          公司简介
        </div>
        <div
          class="item"
          :class="navAction == 4 ? 'item-action' : ''"
          @click="toMaodian('huiyuan', 4)"
        >
          会员充值
        </div>
        <div
          class="item"
          :class="navAction == 3 ? 'item-action' : ''"
          @click="toMaodian('contact', 3)"
        >
          联系我们
        </div>
      </div>
    </div>
    <div class="body" v-if="show == 'body'">
      <div class="home" id="home" v-if="navAction == 1">
        <img src="https://p5.ssl.qhimg.com/t013722804bbb13776b.png" alt="" />
      </div>
      <div class="huiyuan" id="huiyuan" v-if="navAction == 4">
        <div class="huiyuan-content" v-if="huiyuanxianshi == 1">
          <div class="individual">
            <div class="up-button" @click="upload(99)">立即升级</div>
          </div>
          <div class="commercial">
            <div class="up-button" @click="upload(199)">立即升级</div>
          </div>
        </div>
        <div class="huiyuan-content-click" v-if="huiyuanxianshi == 2">
          <div class="item-list">
            <div
              class="individual"
              :class="chongzhijine == 99 ? 'selected' : ''"
              @click="upload(99)"
            >
              <img
                src="https://p5.ssl.qhimg.com/t01a3ac6d8d6916bb9f.png"
                alt=""
              />
            </div>
            <div
              class="commercial"
              :class="chongzhijine == 199 ? 'selected' : ''"
              @click="upload(199)"
            >
              <img
                src="https://p0.ssl.qhimg.com/t013c9d621defedd48c.png"
                alt=""
              />
            </div>
          </div>
          <div class="item-detail">
            <div class="item-detail-tips">
              应付金额：<span
                >￥<b>{{ chongzhijine }}</b></span
              >(共一年)
            </div>
            <div class="qr-code">
              <img :src="'data:image/png;base64,'+qrcodeUrl" alt="">
              <div>微信支付</div>
            </div>
            <div class="tips-2">支付即视为您同意《VIP服务协议》</div>
          </div>
        </div>
        <div class="pay-sunccess"  v-if="huiyuanxianshi == 3">
          <div class="touserdetail" @click="touserdetail">去查看</div>
        </div>
        <div class="huiyuanxinxi" v-if="huiyuanxianshi == 4"></div>
        <div class="login-pop-up" v-if="loginpopup">
          <div class="login">
            <div class="close" @click="close"></div>
            <div class="title">登录</div>
            <div class="from-item">
              <div class="label">用户名*</div>
              <div class="input">
                <input
                  type="text"
                  placeholder="请输入用户名"
                  v-model="userName"
                />
              </div>
            </div>
            <div class="from-item">
              <div class="label">密码*</div>
              <div class="input">
                <input type="password" v-model="pwd" placeholder="请输入密码" />
              </div>
            </div>
            <div class="buttons">
              <div
                class="login-button"
                @click="login()"
                style="margin-left: 80px"
              >
                登录
              </div>
              <div class="toregister-button" @click="gotoLink('register')">
                去注册
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zuopin" id="zuopin" v-if="navAction == 2">
        <div class="nav-tobar-zuopin">
          <div
            class="item"
            :class="zuopinAction == 0 ? 'item-action' : ''"
            @click="zuopinChange(0)"
          >
            推荐
            <div class="solid" v-if="zuopinAction == 0"></div>
          </div>
          <div
            class="item"
            :class="zuopinAction == 1 ? 'item-action' : ''"
            @click="zuopinChange(1)"
          >
            免费
            <div class="solid" v-if="zuopinAction == 1"></div>
          </div>
          <div
            class="item"
            :class="zuopinAction == 2 ? 'item-action' : ''"
            @click="zuopinChange(2)"
          >
            插画
            <div class="solid" v-if="zuopinAction == 2"></div>
            <div class="second-menu" v-if="zuopinAction == 2 && showmenu" >
              <div
                class="second-menu-item"
                :class="secondmenu == 0 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(0)"
              >
                手绘
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 1 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(1)"
              >
                肌理
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 2 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(2)"
              >
                治愈风
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 3 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(3)"
              >
                矢量扁平
              </div>
            </div>
          </div>
          <div
            class="item"
            :class="zuopinAction == 3 ? 'item-action' : ''"
            @click="zuopinChange(3)"
          >
            海报
            <div class="solid" v-if="zuopinAction == 3"></div>
            <div class="second-menu" v-if="zuopinAction == 3 && showmenu">
              <div
                class="second-menu-item"
                :class="secondmenu == 4 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(4)"
              >
                促销海报
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 5 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(5)"
              >
                节日海报
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 6 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(6)"
              >
                旅游海报
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 7 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(7)"
              >
                美食海报
              </div>
            </div>
          </div>
          <div
            class="item"
            :class="zuopinAction == 4 ? 'item-action' : ''"
            @click="zuopinChange(4)"
          >
            动漫
            <div class="solid" v-if="zuopinAction == 4"></div>
            <div
              class="second-menu"
              v-if="zuopinAction == 4 && showmenu"
              style="height: 129px; bottom: -129px"
            >
              <div
                class="second-menu-item"
                :class="secondmenu == 8 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(8)"
              >
                动漫壁纸
              </div>
              <div
                class="second-menu-item"
                :class="secondmenu == 9 ? 'second-menu-item-seled' : ''"
                @click.stop="secondMenuChange(9)"
              >
                动漫人物
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <TestComponent
            :imgData="jingpingtuijian"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 0"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="mianfei"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 1"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="shilaingchahua"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 2 && secondmenu == null"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="shouhui"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 2 && secondmenu == 0"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="jili"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 2 && secondmenu == 1"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="zhiyufeng"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 2 && secondmenu == 2"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="shiliangbianping"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 2 && secondmenu == 3"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="changyihaibao"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 3 && secondmenu == null"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="cxhb"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 3 && secondmenu == 4"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="jehb"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 3 && secondmenu == 5"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="lyhb"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 3 && secondmenu == 6"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="mshb"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 3 && secondmenu == 7"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="dongman"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 4 && secondmenu == null"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="mhbz"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 4 && secondmenu == 8"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <TestComponent
            :imgData="dmrw"
            :zuopinAction="zuopinAction"
            v-if="zuopinAction == 4 && secondmenu == 9"
            @childData="receiveDataFromChild"
          ></TestComponent>
          <!-- <div class="item" 
              v-for="item in zuopinList"
              :key="item.value"><img :src="item.listImg" alt="" @click="toDetail(item)"></div> -->
        </div>
      </div>
      <div class="contact" id="contact" v-if="navAction == 3">
        <img src="https://p4.ssl.qhimg.com/t01c33c7e158ba6305e.png" alt="" />
      </div>
      <div class="footer">
        <img src="https://p0.ssl.qhimg.com/t016bbe2af18b80e4e9.png" alt="" />
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="color: #fff; margin-top: 10px"
          target="_blank"
          >ICP备案号:京ICP备2023004335号-1</a
        >
        
        <a
          href="https://p5.ssl.qhimg.com/t01f66addd4ff50030f.png"
          style="color: #fff; margin-top: 10px"
          target="_blank"
          >网络文化经营许可证:京网文〔2024〕1734-083 </a
        >
        <a
          href="https://p1.ssl.qhimg.com/t016334426cc7c7dbac.png"
          style="color: #fff; margin-top: 10px"
          target="_blank"
          >营业执照</a
        >
      </div>
    </div>
    <div class="img-detail" v-show="show == 'img'">
      <div class="header-title">
        <div class="left">
          <div class="title">{{ imgDetail.title }}</div>
          <div class="name">{{ imgDetail.name }}</div>
        </div>
        <div class="downLoad" @click="gotoLink('pay')">下载</div>
      </div>
      <div class="content-img">
        <el-watermark :font="font" style="height: 581px" content="神州动漫">
          <img :src="imgDetail.contentImg" alt="" ref="imagesRef" />
        </el-watermark>
      </div>
    </div>
    <div class="login-detail" v-if="show == 'login'">
      <div class="login">
        <div class="title">登录</div>
        <div class="from-item">
          <div class="label">用户名*</div>
          <div class="input">
            <input type="text" placeholder="请输入用户名" v-model="userName" />
          </div>
        </div>
        <div class="from-item">
          <div class="label">密码*</div>
          <div class="input">
            <input type="password" v-model="pwd" placeholder="请输入密码" />
          </div>
        </div>
        <div class="buttons">
          <div class="login-button" @click="login()">登录</div>
          <div class="toregister-button" @click="gotoLink('register')">
            去注册
          </div>
        </div>
      </div>
    </div>
    <div class="register-detail" v-if="show == 'register'">
      <div class="login">
        <div class="title">注册</div>
        <div class="content">
          <div class="from-item">
            <div class="label">用户名*</div>
            <div class="input">
              <input
                type="text"
                placeholder="请输入用户名"
                maxlength="11"
                v-model="register.user_name"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="label">邮箱*</div>
            <div class="input">
              <input
                type="text"
                placeholder="请输入邮箱"
                v-model="register.email"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="label">密码*</div>
            <div class="input">
              <input
                type="password"
                placeholder="请输入密码"
                v-model="register.password"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="label">真实姓名*</div>
            <div class="input">
              <input
                type="text"
                placeholder="请输入真实姓名"
                v-model="register.real_name"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="label">确认密码*</div>
            <div class="input">
              <input type="password" placeholder="请输入确认密码" 
                v-model="register.pwd"/>
            </div>
          </div>
          <div class="from-item">
            <div class="label">身份证号*</div>
            <div class="input">
              <input
                type="text"
                placeholder="请输入身份证号"
                v-model="register.id_card"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <div class="toregister-button" @click="toregister">去注册</div>
        </div>
      </div>
    </div>
    <div class="pay-detail" v-if="show == 'pay'">
      <div class="tips">提示：本站动漫作品，需支付费用才可下载观看</div>
      <img
        src="https://p1.ssl.qhimg.com/t01df9813d2021f7201.png"
        alt=""
        style="width: 360px; height: 472px"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-eval */
import "./index.css";
import { ref, reactive } from "vue";
// import VueWaterfall2 from 'vue-waterfall2';
import TestComponent from "./TestComponent";
import axios from "axios";
import {
  jingpingtuijian,
  mianfei,
  shilaingchahua,
  changyihaibao,
  dongman,
  shouhui,
  jili,
  shiliangbianping,
  zhiyufeng,
  cxhb,
  jehb,
  lyhb,
  mshb,
  mhbz,
  dmrw,
} from "./lianjie";

const font = reactive({
  color: "rgba(0, 0, 0, .35)",
});
// let Marker = ref('')
let navAction = ref(2);
let zuopinAction = ref(0);
let pwd = ref("");
let loginFrom = ref("");
let userName = ref("");
let huiyuanxianshi = ref(1);
let show = ref("body");
let secondmenu = ref(null);
let loginpopup = ref(false);
let chongzhijine = ref(99);
let register = ref({});
let showmenu = ref(false)
let qrcodeUrl = ref('')
let imgDetail = ref({
  listImg: "https://p1.ssl.qhimg.com/t017eb071c17ea3b06b.png",
  contentImg: "https://p2.ssl.qhimg.com/t016c43bd3c5792f22d.png",
  title: "九州第一少主",
  name: "西瓜头子",
});
let out_trade_no = ref(0)
const imagesRef = ref(null);
import { ElLoading,ElMessage  } from "element-plus";
let lologin = localStorage.getItem("login");
if (lologin) {
  let uname = localStorage.getItem("userName");
  userName.value = uname;
  loginFrom.value = "login";
}
let ispay = localStorage.getItem("ispay");
if (ispay) {
  huiyuanxianshi.value = 4;
}
// const show = () => {
//   search.value = false
// }
/**
 * 获取图片的 base64 编码 DataURL
 * @param image JS 图像对象
 * @return {string} 已编码的 DataURL
 */
//  const getImageDataURL = (image) => {
//     // 创建画布
//     const canvas = document.createElement('canvas');
//     canvas.width = image.width;
//     canvas.height = image.height;
//     const ctx = canvas.getContext('2d');
//     // 以图片为背景剪裁画布
//     ctx.drawImage(image, 0, 0, image.width, image.height);
//     // 获取图片后缀名
//     const extension = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
//     // 某些图片 url 可能没有后缀名，默认是 png
//     return canvas.toDataURL('image/' + extension, 1);
// }
// const downLoad = (downloadName, url) => {
//     const tag = document.createElement('a');
//     // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
//     tag.setAttribute('download', downloadName.replace(/\./g, '。'));

//     const image = new Image();
//     // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
//     image.src = url + '?time=' + new Date().getTime();
//     //重要，设置 crossOrigin 属性，否则图片跨域会报错
//     image.setAttribute('crossOrigin', 'Anonymous');
//     // 图片未加载完成时操作会报错
//     image.onload = () => {
//         tag.href = getImageDataURL(image);
//         tag.click();
//     };
// }
// const doImg = (name,url) => {
//   downLoad(name,url)
// }
const toMaodian = (id, index) => {
  show.value = "body";
  navAction.value = index;
        clearInterval(timer)
  // id 在当前页面必须唯一的，否则会导致定位不到具体内容
  // const scrollDom = document.getElementById(id);
  // try {
  //   scrollDom.scrollIntoView({
  //     behavior:'smooth'
  //   });
  // } catch (error) {
  //   console.log(error)
  // }
};
const gotoLink = (item) => {
  show.value = item;
  if (item == "body") {
    navAction.value = 0;
  }
};
const zuopinChange = (index) => {
  secondmenu.value = null;
  zuopinAction.value = index;
  showmenu.value = true
};
const secondMenuChange = (index) => {
  showmenu.value = false
  secondmenu.value = index;
};
const receiveDataFromChild = () => {
  if (loginFrom.value == "login") {
    gotoLink("body");
    toMaodian("huiyuan", 4);
  } else {
    loginFrom.value = "zuopin";
    gotoLink("login");
  }
};
const login = () => {
  const loading = ElLoading.service({
    lock: true,
    text: "登录中",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let data = {
    user_name: userName.value,
    password: pwd.value,
  };
  axios
    .post("https://web.bjzonghkjicp.cn/video/comic/login", data)
    .then((res) => {
      console.log(res);
      if (res.data.code == 0) {
        ElMessage({
          message: '登录成功',
          type: 'success',
        })
        localStorage.setItem("login", true);
        localStorage.setItem("userName", res.data.data.user_name);
        if (loginFrom.value == "zuopin") {
          gotoLink("body");
          toMaodian("huiyuan", 4);
        } else {
          gotoLink("body");
          loginpopup.value = false;
          if (loginFrom.value == "huiyuanxianshi") {
            huiyuanxianshi.value = 2;
            getQrCode()
          }
          navAction.value = 4;
        }
        loginFrom.value = "login";
        loading.close();
      } else {
        if (res.data.data == "record not found") {
          ElMessage({
            message: '此账号没有注册请先去注册',
            type: 'warning',
          })
        }
        if (res.data.data == "登录失败") {
          ElMessage({
            message: '密码错误',
            type: 'warning',
          })
        }
        loading.close();
      }
    });
};
// const toDetail = (item) => {
//   imgDetail.value = item
//   show.value = 'img'
// }
const close = () => {
  loginpopup.value = false;
};
const upload = (index) => {
  let login = localStorage.getItem("login");
  chongzhijine.value = index;
  if (login) {
    huiyuanxianshi.value = 2;
    getQrCode()
  } else {
    loginFrom.value = "huiyuanxianshi";
    loginpopup.value = true;
  }
};
let timer = null
const getQrCode = () => {
  let data = {
    user_name: userName.value,
    amount: chongzhijine.value*100,
    // amount: 1,
  };
  axios
    .post("https://web.bjzonghkjicp.cn/video/comic/prepay", data)
    .then((res) => {
      console.log(res);
      if (res.data.code == 0) {
        qrcodeUrl.value = res.data.data.code_url
        out_trade_no.value = res.data.data.out_trade_no
        clearInterval(timer)
        timer = setInterval(()=>{
          queryOrder()
        },2000)
      } else {
        console.log('错误')
      }
    });
}
const queryOrder = ()=>{
  axios
    .get("https://web.bjzonghkjicp.cn/video/comic/querycomicorder?out_trade_no="+out_trade_no.value)
    .then((res) => {
      console.log(res);
      if (res.data.code == 0 && res.data.data) {
        console.log('成功')
        clearInterval(timer)
        huiyuanxianshi.value = 3
        setTimeout(()=>{
          huiyuanxianshi.value = 4
        },1000)
        localStorage.setItem('ispay',true)
      }  else {
        console.log('错误')
      }
    });
}
const touserdetail = () => {
  huiyuanxianshi.value = 4
}
const toregister = () => {
  if(register.value.pwd != register.value.password) {
    ElMessage({
      message: '两次密码不一致',
      type: 'warning',
    })
    return false
  }
  if(register.value.user_name && register.value.user_name.length < 6) {
    ElMessage({
      message: '用户名最短为6位最长为11位',
      type: 'warning',
    })
    return false
  }
  if(register.value.password && register.value.password < 6) {
    ElMessage({
      message: '密码最短为6位',
      type: 'warning',
    })
    return false
  }
  if(register.value.id_card && register.value.id_card.length != 18) {
    ElMessage({
      message: '身份证号必须为18位',
      type: 'warning',
    })
    return false
  }
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  if(register.value.email && !emailRegex.test(register.value.email)) {
    ElMessage({
      message: '邮箱格式不正确！',
      type: 'warning',
    })
    return false
  }
  var chineseCharactersRegex = /^[\u4e00-\u9fa5]+$/;
  
  if(register.value.real_name && !chineseCharactersRegex.test(register.value.real_name)) {
    ElMessage({
      message: '真实姓名包含非汉字字符。',
      type: 'warning',
    })
    return false
  }
  const loading = ElLoading.service({
    lock: true,
    text: "注册中",
    background: "rgba(0, 0, 0, 0.7)",
  });
  axios
    .post("https://web.bjzonghkjicp.cn/video/comic/register", register.value)
    .then((res) => {
      console.log(res);
      if (res.data.code == 0) {
        ElMessage({
          message: '注册成功',
          type: 'success',
        })
        gotoLink("login");
        loading.close();
      } else {
        ElMessage({
          message: '请检查信息',
          type: 'warning',
        })
        loading.close();
      }
    });
};
const outLogin = ()=>{
  localStorage.clear();
  location.reload();
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cell-item {
  width: 100%;
  background: #ffffff;
  border: 2px solid #f0f0f0;
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}
.hello {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-width: 1440px;
}
/* .img-down{} */
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
